import { t } from "i18next";
import {
  dsMonitorIcon,
  monitorInfoImg,
  monitorMonthlyImg,
} from "../../../assets/images";
import { Switch } from "antd";
import { Link } from "react-router-dom";
import { renderNoFoundData } from "../../../utils/commonUtils";
import { useSelector } from "react-redux";
import MonitoringReportModal from "../Modals/MonitoringReportModal/MonitoringReportModal";
import { useState } from "react";

const NoInfoFound = (props) => {
  const { sectionName, id } = props;
  const [monitorModal, setMonitorModal] = useState(false)

  const phoneReport = useSelector(
    (state) =>
      state?.getPhoneReportDetails?.getPhoneReportDetailsData?.data
        ?.phoneReport || {}
  );

  const ownerName = phoneReport?.phone_owner_info?.data?.name || "User";
  const phoneNumber = phoneReport?.phone_number || "N/A";

  const item = renderNoFoundData(sectionName, ownerName, phoneNumber);
  return (
    <>
      {monitorModal &&
        <MonitoringReportModal
          isOpen={monitorModal}
          setMonitorModal={setMonitorModal}
        />}
    <div className="monitor_wrapper monitor-white-bg-wrap" id={id}>
      {item.sectionTitle && (
        <div className="ds--unlock__title left-content main_title">
          <h2>{item.sectionTitle}</h2>
        </div>
      )}
      <div className="monitor_monthly_wrap">
        {item.noFoundText && (
          <div className="monthly_info_wrap">
            <p>
              <img src={monitorInfoImg} alt="Information" />
              {item.noFoundText}&nbsp;
            </p>
          </div>
        )}
        {(item.description || item.subDescription) && (
          <div className="monthly_update_wrap">
            <h3>
              {t("PR_UPDATE_MONTHLY")}{" "}
              <img src={monitorMonthlyImg} alt="Monthly Update" />
            </h3>
            {item.description && <p>{item.description}</p>}
            {item.subDescription && <p>{item.subDescription}&nbsp;</p>}
          </div>
        )}
      </div>
      {(item.monitorDesc || item.monitorSubDesc) && (
        <div className="monitor__ds_btn ds--monitor-report">
          <div className="monitor-cmp-wrap">
            <img src={dsMonitorIcon} alt="Monitor Icon" />
            {item.monitorDesc && <h2>{item.monitorDesc}</h2>}
            <div className="monitor_switch_btn complaints__btn">
              <Link to="javascript:void(0)" className="complaints__btn">
                  {t("PR_MONITOR_STATUS")} <Switch defaultChecked onChange={() => setMonitorModal(true)} />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default NoInfoFound;
