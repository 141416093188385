import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import {
  AccessReport,
  MyAccount,
  PreviewPopup,
  PhoneReportDashboard,
  ContactUs,
  FaqComponent,
  NoInformationFound,
  LocationTrack,
  CookiesPolicy,
  TermConditions,
  PrivacyPolicy,
} from "./pages";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const PhoneReportsRoutes = () => {
  const stripePromise = loadStripe(
    "pk_test_51NJ6tvLFvpGNSJY705FwsTG4A6KRBTzvhIxLq2wCRCNbQe8LkTQ2F6QAn6jyLOzAlNNyz8IhjRSzvgncfNYlWNZ100k1daXRrv"
  );
  const location = useLocation();
  useEffect(() => {

    const pathnameParts = location.pathname.split("/");
    const currentLang = pathnameParts[1];

    // Apply RTL direction and unique class if the language is "ar" or "he"
    const rootElementRoot = document.getElementById("root");

    const bigLanguages = ["cs", "fr", "uk", "de", "hr", "es", "it", "is", "pt", "ga", "bn", "pt-br", "tr", "el", "et", "ja", "hu", "nl", "sv", "fi", "no", "pl", "ro", "sl", "bg", "yue", "ko", "hi", "id", "ms", "sr", "sk", "th", "da", "zh", "zh-CN"];
    if (bigLanguages.includes(currentLang)) {
      rootElementRoot.classList.add("high__lang_wrapper");
    } else {
      rootElementRoot.classList.remove("high__lang_wrapper");
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <>
      <Route
        path="/:lang/location-preview-popup"
        element={<PreviewPopup />}
      ></Route>
      <Route
        path="/:lang/access-report"
        element={
          <Elements stripe={stripePromise}>
            <AccessReport />
          </Elements>
        }
      ></Route>
      <Route
        path="/:lang/my-account"
        element={
          <Elements stripe={stripePromise}>
            <MyAccount />
          </Elements>
        }
      ></Route>
      <Route path="/:lang/dashboard" element={<PhoneReportDashboard />}></Route>
      <Route path="/:lang/contact-us" element={<ContactUs />}></Route>
      <Route
        path="/:lang/no-information-found"
        element={<NoInformationFound />}
      ></Route>
      <Route path="/:lang/faqs" element={<FaqComponent />}></Route>
      <Route path="/:lang/location" element={<LocationTrack />}></Route>
      <Route path="/:lang/cookiePolicy" element={<CookiesPolicy />}></Route>
      <Route path="/:lang/privacyPolicy" element={<PrivacyPolicy />}></Route>
      <Route path="/:lang/termsConditions" element={<TermConditions />}></Route>
    </>
  );
};

export default PhoneReportsRoutes;
