import React from "react";
import "./phoneGpsComponent.scss";
import {
  listingEllipse,
  tableEllipse,
  trackSearchIcon,
  verifyPhoneIcon,
  wclVerifyPhoneIcon,
  wplTrackSearchIcon,
} from "../../../assets/images";
import { LocatingModal } from "../../partials/Modals";
import { useTranslation } from "react-i18next";
import { formatDate, mapOptions } from "../../../utils/commonUtils";
import { GoogleMap, InfoWindowF, MarkerF } from "@react-google-maps/api";
import { connect } from "react-redux";

const PhoneGpsComponent = ({
  setIsOpen,
  number,
  phoneReportDetails,
  center,
  latLong,
  inFowindowOpen,
  isLoaded,
  setInFowindowOpen,
  handleChangeMap,
}) => {
  const { t } = useTranslation();
  const { locations } = phoneReportDetails?.phoneReport || {};

  return (
    <>
      <div className="phone-report-wrap white-bg-wrap">
        <div className="phone-report-box">
          <div className="phone-report-img">
            {/* <img src={verifyPhoneIcon} alt="" /> */}
            <img src={wclVerifyPhoneIcon} alt="" />
          </div>
          <div className="phone-report-text">
            <h2>{t("PR_PHONE_REPORT")}</h2>
            <div className="phone-report-info">
              <p>
                <a>{number ? number : "+1 (819) 674-0000"}</a>
              </p>
              <ul>
                <li>
                  <img src={listingEllipse} alt="" />{" "}
                  <b>{t("PR_STEP_3_PHONE")}:</b> {t("PR_STEP_3_MOBILE")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="location-info-wrap white-bg-wrap" id={"phoneGpsLocation"}>
        <div className="main_title">
          <h2 className="text-left">{t("PR_STEP_3_TITLE_3")}</h2>
        </div>
        <div className="location_table_info">
          {!locations || locations.length <= 0 ? (
           ""
          ) : (
            <table>
              <thead>
                <tr>
                  <th className="border-right">
                    {t("PR_STEP_3_DATE").toUpperCase()}
                  </th>
                  <th>{t("PR_STEP_3_STATUS").toUpperCase()}</th>
                </tr>
              </thead>
              {locations &&
                locations.map((item) => {
                  return (
                    <tbody
                      onClick={() =>
                        item.geo &&
                        handleChangeMap(
                          item.geo.lat,
                          item.geo.long,
                          item.geo.formatted
                        )
                      }
                    >
                      <tr>
                        <td className="border-right">
                          {formatDate(item.updatedAt)}
                        </td>
                        <td>
                          <span>
                            {item.status
                              ? item?.geo?.formatted
                              : t("SMS_STATUS_PENDING")}{" "}
                            {!item.status && <img src={tableEllipse} alt="" />}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
            </table>
          )}
        </div>
        <div className="location_map">
          {isLoaded && (
            <div className="dashboard-map dashboard-box">
              <div
                className="map-container"
                style={{
                  height: "500px",
                  borderRadius: "15px",
                  overflow: "hidden",
                }}
              >
                {isLoaded && (
                  <GoogleMap
                    zoom={latLong?.length > 1 ? 15 : 10}
                    center={center}
                    mapContainerStyle={{ height: "100%", width: "100%" }}
                    options={mapOptions}
                  >
                    {latLong?.map((marker, index) => (
                      <MarkerF
                        key={index}
                        position={marker.latlng}
                        onClick={() => setInFowindowOpen(marker.latlng.lat)}
                      >
                        {inFowindowOpen === marker.latlng.lat && (
                          <InfoWindowF>
                            <p>{marker.address}</p>
                          </InfoWindowF>
                        )}
                      </MarkerF>
                    ))}
                  </GoogleMap>
                )}
              </div>
            </div>
          )}
        </div>

          <div className="defult_track_btn" onClick={() => setIsOpen(true)}>
            <p>
              <a className="track_button" href="javascript:void(0)">
                <span>
                  {t("PR_ACCESS_REPORT_TEXT_1")} {"  "}
                  <strong>{number ? number : " +1 (819) 674-0000"}</strong>
                </span>{" "}
                <img src={wplTrackSearchIcon} alt="" />
              </a>
            </p>
          </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sendLocation: state.sendLocation,
    sendDetailsData: state.sendDetailsData,
    getPhoneReportDetails: state.getPhoneReportDetails,
    callCreateFirstReport: state.callCreateFirstReport,
  };
};

export default connect(mapStateToProps)(PhoneGpsComponent);
