/* global gtag */
import React, { useEffect, useState } from "react";
import {
  faqsIcon,
  menuAccountIcon,
  menuContactIcon,
  menuDashboardIcon,
  newHeaderLogo,
  user_profile,
} from "../../../assets/images";
import "./header.scss";
import { Drawer, Dropdown, Modal, Spin } from "antd";
import { IoCloseCircle, IoSearch } from "react-icons/io5";
import { TbLogout, TbHelp } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import { unsubscribe } from "../../../../redux/slice/unsubscribeSlice";
import { useTranslation } from "react-i18next";
import Notify from "../../../../components/common/Notify/notify";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "libphonenumber-js";
import {
  checkIsPhoneNmberExists,
  createLocation,
  sendLocation,
} from "../../../redux/slice";
import { renderRTLClass } from "../../../../utils/commonUtils";

const PhoneReportHeader = ({
  authenticate,
  sendDetailsData,
  callCheckIsPhoneNmberExists,
  callCreateLocation,
  callsendLocation,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const userEmail = authenticate?.authenticateData?.data?.email || "";
  const [openDrawer, setOpenDrawer] = useState(false);
  const [token, setToken, removeToken] = useCookies(["token"]);
  const [roles, setRole, removeRole] = useCookies(["role"]);
  const [step, setStep, removeStep] = useCookies(["step"]);
  const [seephoneReport, setphoneReport, removephoneReport] = useCookies([
    "phoneReport",
  ]);

  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [countryShortName, setCountryShortName] = useState("");
  const [numberError, setNumberError] = useState("");
  const [numberValidate, setNumberValidate] = useState("");
  const [countryCode, setCountryCode] = useState(+1);
  const [number, setNumber] = useState("");

  useEffect(() => {
    if (countryCode.toString().includes("+")) {
      setNumber(countryCode.replace("+", ""));
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (
      sendDetailsData &&
      sendDetailsData.getSendDetailsData &&
      sendDetailsData.getSendDetailsData.data
    ) {
      setCountryShortName(sendDetailsData.getSendDetailsData.data.country);
    }
  }, [sendDetailsData]);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleNavigate = (path) => {
    navigate(`/${lang}/${path}`);
    openDrawer && handleCloseDrawer();
  };

  const handleLogout = () => {
    removeToken(["token"]);
    removeRole(["role"]);
    removeStep(["step"]);
    removephoneReport(["phoneReport"]);
    const cookieKeys = Array.isArray(cookies.getAll())
      ? cookies.getAll()
      : Object.keys(cookies.getAll());
    cookieKeys.forEach((key) => {
      if (key !== "currency") {
        cookies.remove(key, { path: "/" });
      }
    });
    window.location.href = "/";
  };

  function isActiveRoute(route) {
    const currentPath = location.pathname;
    return currentPath.includes(route);
  }

  const items = [
    {
      key: "1",
      label: (
        <div
          className={`mobile_menu_option ${
            isActiveRoute("dashboard") && "active"
          }`}
          onClick={() => handleNavigate("dashboard")}
        >
          <img src={menuDashboardIcon} alt="" />
          <span>{t("PR_DASHBOARD")}</span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className={`mobile_menu_option ${
            isActiveRoute("my-account") && "active"
          }`}
          onClick={() => handleNavigate("my-account")}
        >
          <img src={menuAccountIcon} alt="" />
          <span>{t("PR_ACCOUNT")}</span>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          className={`mobile_menu_option ${
            isActiveRoute("contact-us") && "active"
          }`}
          onClick={() => handleNavigate("contact-us")}
        >
          <img src={menuContactIcon} alt="" />
          <span>{t("CONTACT_US_TITLE")}</span>
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          className={`mobile_menu_option ${isActiveRoute("faqs") && "active"}`}
          onClick={() => handleNavigate("faqs")}
        >
          <TbHelp />
          <span>{t("PR_FAQ")}</span>
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div className="mobile_menu_option" onClick={handleLogout}>
          <TbLogout />
          <span>{t("PR_LOGOUT_PHONE_REPORT")}</span>
        </div>
      ),
    },
  ];

  const handleOnNumberChange = (value, country) => {
    setCountryCode(`+${country.dialCode}`);
    setNumber(value);
    setCountryShortName(country.countryCode.toUpperCase());
    setNumberValidate("");
    setNumberError("");
  };

  const handleSendMessage = async (isCreateLocation) => {
    const sender_mobile_number = `+${number}`
      .toString()
      .replace(countryCode, "");
    if (number) {
      if (
        isValidPhoneNumber(
          sender_mobile_number,
          `${
            countryShortName
              ? countryShortName.toString().toUpperCase()
              : number.toUpperCase()
          }`
        )
      ) {
        const data = {
          sender_country_code: countryCode === 1 ? "+1" : countryCode,
          sender_mobile_number: sender_mobile_number,
        };
        setLoading(true);
        let result;
        if (isCreateLocation) {
          setLoading(true);
          result = await callCreateLocation(data);
          const { payload } = result || {};
          const { data: responseData } = payload;
          const { _id } = responseData;
          setLoading(false);
          navigate(
            `/${lang}/access-report?D=${
              countryCode === 1 ? "+1" : countryCode
            }&n=${`+${number}`.toString().replace(countryCode, "")}&id=${_id}`
          );

          Notify("success", t("NOTIFY_SEND_LOCATION"), "");
          setLoading(false);
          setNumber(countryCode);
        } else {
          setLoading(true);
          result = await callsendLocation(data);
          setLoading(false);
        }
        if (result.type === "sendLocation/fulfilled") {
          Notify("success", t("NOTIFY_SEND_LOCATION"), "");
          document.body.style.overflow = "unset";
          setLoading(false);
          setNumber(countryCode);
          navigate(`/${lang}/access-report`);
        } else if (result.type === "sendLocation/rejected") {
          // setResultError(result.error.message);
          document.body.style.overflow = "unset";
          setLoading(false);
        }
      } else {
        if (sender_mobile_number === "" || sender_mobile_number === " ") {
          setNumberError(t("ERROR_ENTER_NUMBER_DASHBOARD"));
        } else {
          setNumberValidate(t("ERROR_VALID_NUMBER_DASHBOARD"));
        }
      }
    } else {
      setNumberError(t("ERROR_ENTER_NUMBER_DASHBOARD"));
    }
  };

  const handleOpenLocateModal = async () => {
    if (!number && number !== 0) {
      setNumberError(t("ERROR_ENTER_NUMBER_DASHBOARD"));
      return;
    } else if (
      !isValidPhoneNumber(
        number,
        `${
          countryShortName
            ? countryShortName.toUpperCase()
            : number.toUpperCase()
        }`
      )
    ) {
      setNumberValidate(t("ERROR_VALID_NUMBER_DASHBOARD"));
      return;
    }
    setNumberValidate("");
    const phone_number_prefix = countryCode === 1 ? "+1" : countryCode;
    const phone_number = `+${number}`
      .toString()
      .replace(phone_number_prefix, "");

    const data = {
      phone_number_prefix: phone_number_prefix,
      phone_number: phone_number,
    };

    setLoading(true);
    const result = await callCheckIsPhoneNmberExists(data);
    setLoading(false);
    const { payload } = result || {};
    const { data: responseData } = payload || {};
    const { success, info } = responseData || {};
    const { _id } = info || {};
    if (!success && number) {
      handleSendMessage(!success);
    } else {
      if (number && number !== 0) {
        if (
          isValidPhoneNumber(
            number,
            `${
              countryShortName
                ? countryShortName.toUpperCase()
                : number.toUpperCase()
            }`
          )
        ) {
          navigate(
            `/${lang}/access-report?D=${
              countryCode === 1 ? "+1" : countryCode
            }&n=${`+${number}`.toString().replace(countryCode, "")}&id=${_id}`
          );
          setNumberError("");
        } else {
          setNumberValidate(t("ERROR_VALID_NUMBER_DASHBOARD"));
        }
      } else {
        setNumberError(t("ERROR_ENTER_NUMBER_DASHBOARD"));
      }
    }
  };
  return (
    <>
      <Spin spinning={loading}>
        <header className="header_wrap">
          <div className="main_container_header">
            <div className="header_wrap_area">
              <div
                className="header_logo"
                onClick={() => handleNavigate("dashboard")}
              >
                <img src={newHeaderLogo} alt="" width={200} height={45} />
              </div>
              <div className="header_functionality">
                <div className="searchbar_wrapper search-animation-set">
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      required: true,
                      className: "form-control",
                    }}
                    country={countryShortName.toLowerCase()}
                    value={number}
                    autoFormat={false}
                    placeholder={t("PR_ENTER_NUMBER")}
                    onChange={handleOnNumberChange}
                    onEnterKeyPress={handleOpenLocateModal}
                    countryCodeEditable={false}
                    className="web-input"
                  />
                  <IoSearch
                    className="search-animation-search-icon"
                    onClick={handleOpenLocateModal}
                  />
                  {numberError && <p className="Number_Error">{numberError}</p>}
                  {numberValidate && (
                    <p className="Number_Error">{numberValidate}</p>
                  )}
                </div>
                <div className="searchbar_wrapper mobile-drawer">
                  <div className="openbtn" onClick={() => setOpenDrawer(true)}>
                    <div className="openbtn-area">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>

                <div className="searchbar_wrapper desktop-drawer">
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomRight"
                    trigger={["click"]}
                    overlayClassName={`dropdown-setting ${renderRTLClass()}`}
                  >
                    <div>
                      <div
                        className="openbtn"
                        onClick={(e) => e.target.classList.toggle("active")}
                      >
                        <div className="openbtn-area">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
              <div className="mobile-header">
                <PhoneInput
                  inputProps={{
                    name: "phone",
                    required: true,
                    className: "form-control input-field",
                  }}
                  country={countryShortName.toLowerCase()}
                  value={number}
                  autoFormat={false}
                  placeholder={t("BANNER_INPUT")}
                  onChange={handleOnNumberChange}
                  onEnterKeyPress={handleOpenLocateModal}
                  countryCodeEditable={false}
                  className="phone-input"
                />
                <IoSearch
                  className="mobile-header-search-icon"
                  onClick={handleOpenLocateModal}
                />
                {numberError && (
                  <p className="mobile-number-error">{numberError}</p>
                )}
                {numberValidate && (
                  <p className="mobile-number-error">{numberValidate}</p>
                )}
              </div>
            </div>
          </div>
          <Drawer
            title="Title"
            placement={"right"}
            closable={false}
            onClose={handleCloseDrawer}
            open={openDrawer}
            key={"right"}
            className="mobile-drawer"
            maskClosable={false}
            extra={
              <button onClick={handleCloseDrawer} className="menu_close_btn">
                <IoCloseCircle />
              </button>
            }
          >
            <div className="profile_icon">
              <img src={user_profile} alt="" />
              <p>{userEmail}</p>
            </div>
            <div className="mobile-drawer-menu">
              <div
                className={`mobile_menu_option ${
                  isActiveRoute("dashboard") && "active"
                }`}
                onClick={() => handleNavigate("dashboard")}
              >
                <img src={menuDashboardIcon} alt="" />
                <span>{t("PR_DASHBOARD")}</span>
              </div>
              <div
                className={`mobile_menu_option ${
                  isActiveRoute("my-account") && "active"
                }`}
                onClick={() => handleNavigate("my-account")}
              >
                <img src={menuAccountIcon} alt="" />
                <span>{t("PR_ACCOUNT")}</span>
              </div>
              <div
                className={`mobile_menu_option ${
                  isActiveRoute("contact-us") && "active"
                }`}
                onClick={() => handleNavigate("contact-us")}
              >
                <img src={menuContactIcon} alt="" />
                <span>{t("CONTACT_US_TITLE")}</span>
              </div>
              <div
                className={`mobile_menu_option ${
                  isActiveRoute("faqs") && "active"
                }`}
                onClick={() => handleNavigate("faqs")}
              >
                <img src={faqsIcon} alt="" />
                <span>{t("PR_FAQ")}</span>
              </div>
              <div className="mobile_menu_option" onClick={handleLogout}>
                <TbLogout />
                <span>{t("PR_LOGOUT_PHONE_REPORT")}</span>
              </div>
            </div>
          </Drawer>
        </header>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticate: state.authenticate,
    sendDetailsData: state.sendDetailsData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callsendLocation: (data) => dispatch(sendLocation(data)),
    callCheckIsPhoneNmberExists: (data) =>
      dispatch(checkIsPhoneNmberExists(data)),
    callCreateLocation: (data) => dispatch(createLocation(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneReportHeader);
