import React from "react";
import { t } from "i18next";
import {
  copyPastIcon,
  phoneTypeIcon,
  otherPhoneNumbersImg,
  nodataIcon,
} from "../../../assets/images";
import UnlockReport from "../unlockReport/unlockReport";
import { renderValue, toCamelCase } from "../../../utils/commonUtils";
import SectionAction from "../../partials/sectionAction/sectionAction";
import NoInfoFound from "../noInfoFound/noInfoFound";

const OtherPhoneNumber = ({ isLocked, sectionName, reportDetail }) => {
  const { other_phone_numbers } = reportDetail?.phoneReport || {};
  const { data, status } = other_phone_numbers || {};
  const { phone_owner_info } = reportDetail?.phoneReport || {};
  const isData = phone_owner_info?.status === "found";
  return isLocked ? (
    <UnlockReport
      title={t("PR_REPORT_POPUP_TEXT_6")}
      image={otherPhoneNumbersImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_OTHER_NUMBER_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"other_phone_numbers"}
      id={"Other Phone Numbers"}
    />
  ) : !isLocked && status === "not_found" ? (
    <NoInfoFound
      sectionName={sectionName}
      id={toCamelCase("Other Phone Numbers")}
    />
  ) : (
    <div
      className="unlock_phone_type white-bg-wrap"
      id={toCamelCase("Other Phone Numbers")}
    >
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>{t("PR_REPORT_POPUP_TEXT_6")}</h2>
        <SectionAction />
      </div>
      {status === "found" && data.length > 0 && (
        <>
          <div className="unlock_title unlock-description">
            <p>
              {t("PR_OTHER_MESSAGE", {
                phone_owner_full_name: isData
                  ? renderValue(phone_owner_info.data.name)
                  : "USER",
              })}
            </p>
          </div>
          {data.map((item, index) => {
            return (
              <div className="ds--secondary-head" key={index}>
                <div className="ds--phone-image">
                  <img src={phoneTypeIcon} alt="" />
                </div>
                <div class="ds--phone-copy">
                  <p
                    to={""}
                    onClick={() =>
                      navigator.clipboard.writeText(item.phone_number)
                    }
                  >
                    <img src={copyPastIcon} alt="" />
                  </p>
                </div>
                <div className="ds--phone-wrap">
                  <h3>
                    <a href="tel:+1 (819) 674-0000">{item.phone_number}</a>
                  </h3>
                  <div className="ds--phone-content">
                    <p>{t("PR_STEP_3_PHONE")}</p>
                    <span>{renderValue(item.type)}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default OtherPhoneNumber;
