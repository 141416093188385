import React from "react";
import { t } from "i18next";
import { checkEmailBtn, emailAddresses } from "../../../assets/images";
import UnlockReport from "../unlockReport/unlockReport";
import { renderValue, toCamelCase } from "../../../utils/commonUtils";
import SectionAction from "../../partials/sectionAction/sectionAction";
import NoInfoFound from "../noInfoFound/noInfoFound";

const EmailAddress = ({ isLocked, sectionName, reportDetail, number }) => {
  const { emails } = reportDetail?.phoneReport || {};
  const { data, status } = emails || {};

  return isLocked ? (
    <UnlockReport
      title={t("PR_EMAIL_ADDRESS")}
      image={emailAddresses}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_UNLOCK_EMAIL_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"emails"}
      id={"Email Addresses"}
    />
  ) : !isLocked && status === "not_found" ? (
    <NoInfoFound
      sectionName={sectionName}
      id={toCamelCase("Email Addresses")}
    />
  ) : (
    <div
      className="ds--unlock-jobs white-bg-wrap"
      id={toCamelCase("Email Addresses")}
    >
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>{t("PR_EMAIL_ADDRESS")}</h2>
        <SectionAction />
      </div>

      {status === "found" && data.length > 0 && (
        <div className="ds--job-wrapper">
          <p>
            {t("PR_EMAIL_DESCRIPTION_MATCH", {
              phone_number: number,
            })}
          </p>
          {data.map((emailInfo, index) => (
            <div key={index} className="ds--job-box">
              <div className="email--addresses--btn">
                <p to={""} className="address-btn">
                  <img src={checkEmailBtn} alt="" /> {t("PR_MATCH_EMAIL")}{" "}
                </p>
              </div>
              <h3>
                <a href={`mailto:${emailInfo.email}`}>{emailInfo.email}</a>
              </h3>
              <p>
                <b>{t("PR_EMAIL_TYPE")}</b> {renderValue(emailInfo.type)}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EmailAddress;
