import { Switch } from "antd";
import { toCamelCase } from "../../../utils/commonUtils";
import { t } from "i18next";
import { dsMonitorIcon } from "../../../assets/images";
import { useState } from "react";
import MonitoringReportModal from "../Modals/MonitoringReportModal/MonitoringReportModal";

const Monitoring = () => {
  const [monitorModal, setMonitorModal] = useState(false);

  return (
    <>
      {monitorModal && (
        <MonitoringReportModal
          isOpen={monitorModal}
          setMonitorModal={setMonitorModal}
        />
      )}
      <div
        className="ds--monitor-report white-bg-wrap"
        id={toCamelCase("monitoring")}
      >
        <div className="monitor-cmp-wrap">
          <img src={dsMonitorIcon} alt="" />
          <h2>{t("PR_MONITOR_SECTION_TITLE")}</h2>
          <p>{t("PR_MONITOR_SECTION_DESCRIPTION")}</p>
          <div className="monitor_switch_btn complaints__btn">
            <a href="javascript:void(0)" className="complaints__btn">
              {t("PR_MONITOR_SECTION_BUTTON")}
              <Switch defaultChecked onChange={() => setMonitorModal(true)} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Monitoring;
