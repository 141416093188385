import React, { useState } from "react";
import "./settings.scss";
import mainLogo from "../../assets/header/Reversly-logo.svg";
import locateSvg from "../../assets/header/settings.svg";
import { Dropdown, Form, Input, Modal, Space, Spin } from "antd";
import countryList from "react-select-country-list";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Cookies, useCookies } from "react-cookie";
import { RiAdminFill } from "react-icons/ri";
import { FaUsers } from "react-icons/fa";
import { MdPriceChange } from "react-icons/md";
import { useEffect } from "react";
import { HiMenu } from "react-icons/hi";
import { TbLogout } from "react-icons/tb";
import { AiFillSetting } from "react-icons/ai";
import { formatDate, renderRTLClass } from "../../utils/commonUtils";
import { MailOutlined } from "@ant-design/icons";
import ReactCountryFlag from "react-country-flag";
import { languages } from "../../utils/commonUtils";
const SettingsComponent = (props) => {
  const {
    handleUnsubscribe,
    handleChangeDetails,
    details,
    handleUpdateDetails,
    handleChangeCountry,
    successPopup,
    setOpenModal,
    openModal,
    loading,
    unsubscribeResult,
    error,
    authenticate,
    callUpdateUserLanguage
  } = props;
  const cookies = new Cookies();
  const role = cookies.get("role");
  const { t, i18n } = useTranslation();
  const countryOptions = countryList().getData();
  const [showLogo, setShowLogo] = useState("gb");
  const [updatedFlag, setUpdatedFlag] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lang, setLang] = useState(authenticate?.authenticateData?.data?.language);
  const navigate = useNavigate();
  const langCookies = cookies.get("lang");

  useEffect(() => {
    setLang(langCookies)
  }, [langCookies])

  useEffect(() => {
    document.body.classList.toggle("no-scroll", openModal);
    return () => document.body.classList.remove("no-scroll");
  }, [openModal]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  // eslint-disable-next-line
  const [token, setToken, removeToken] = useCookies(["token"]);
  // eslint-disable-next-line
  const [roles, setRole, removeRole] = useCookies(["role"]);
  const handleLogout = () => {
    removeToken(["token"]);
    removeRole(["role"]);
    const cookieKeys = Array.isArray(cookies.getAll())
      ? cookies.getAll()
      : Object.keys(cookies.getAll());
    cookieKeys.forEach((key) => {
      if (key !== "currency" && key !== "lang" && key !== "langlogo") {
        cookies.remove(key, { path: "/" });
      }
    });
    window.location.reload("/");
  };
  // hum-burger-menu
  const items = [
    {
      label: (
        <Link to={`/${lang}/dash`}>
          <img src={locateSvg} alt="" width={20} height={20} />
          <span>{t("DASHBOARD")}</span>
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link to={`/${lang}/settings`} className="active">
          <AiFillSetting />
          <span>{t("FOOTER_ACCOUNT")}</span>
        </Link>
      ),
      key: "1",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/email-test`}>
          <MailOutlined />
          <span>{t("TEST_EMAIL ")}</span>
        </Link>
      ),
      key: "2",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/currency`}>
          <MdPriceChange />
          <span>{t("CURRENCIES ")}</span>
        </Link>
      ),
      key: "3",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/admin`}>
          <RiAdminFill />
          <span>{t("ADMINS")}</span>
        </Link>
      ),
      key: "4",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/users`}>
          <FaUsers />
          <span>{t("DASHBOARD_HEADER_USER")}</span>
        </Link>
      ),
      key: "5",
    },
    {
      label: (
        <Link to={`#`} onClick={handleLogout}>
          <TbLogout />
          <span>{t("LOGOUT")}</span>
        </Link>
      ),
      key: "6",
    },
  ];
  useEffect(() => {
    const find = languages.find((item) => {
      return item.code === lang;
    });
    if (find) {
      cookies.set("langlogo", find.flagCode);
      setUpdatedFlag(find.flagCode)
    }
    // eslint-disable-next-line
  }, [lang, window.location.pathname, cookies]);
  useEffect(() => {
    setShowLogo(updatedFlag);
    // eslint-disable-next-line
  }, [cookies, window.location.pathname]);
  const handleCancel = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "unset";
  };
  //  disable language for temp
  const showModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };
  //  disable language for temp
  const handleChangeLanguage = (lang, logo) => {
    i18n.changeLanguage(lang);
    if (window.location.pathname.split("/")[2]) {
      navigate(`/${lang}/${window.location.pathname.split("/")[2]}`);
    } else {
      navigate(`/${lang}`);
    }
    setIsModalOpen(false);
    document.body.style.overflow = "unset";
    setLang(lang);
    cookies.set("lang", lang, {
      path: "/",
      sameSite: true,
    });
    cookies.set("langlogo", logo, {
      path: "/",
      sameSite: true,
    });
  };

  const updateLang = async () => {
    const result = await callUpdateUserLanguage({
      "language": lang
    });
  }
  useEffect(() => {
    lang && updateLang();
  }, [lang])


  return (
    <div className="dashnoard-main-section">
      <div className="container">
        <div className="dashboard-header">
          <div className="main-logo-img">
            <Link to={`/${lang}/dash`}>
              <img src={mainLogo} alt="" />
            </Link>
          </div>
          {/* disable language for temp  */}
          <div className="header-lang-dropdown">
            <li>
              {showLogo && (
                <div onClick={showModal}>
                  <ReactCountryFlag
                    countryCode={showLogo}
                    svg
                  // onClick={(e) => e.preventDefault()}
                  />
                </div>
              )}
            </li>
          </div>
          <div className="hum-burger-menu">
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              placement="bottomRight"
              overlayClassName={`dashboard-header-menu-mobile ${renderRTLClass()}`}
            >
              <span onClick={(e) => e.preventDefault()}>
                <Space>
                  <HiMenu />
                </Space>
              </span>
            </Dropdown>
          </div>
        </div>
        <div class="account_dash_wrap">
          <div class="mt-50 wpb_content_account account_bg_wrap">
            <div class="setting-find-number">
              <label class="setting-billing-heading">
                <span class="dot"></span>
                <span>{t("ACCOUNT_INFORMATION")}</span>
              </label>
            </div>
            <div class="account_info_inner">
              <div class="wi-50 account_address">
                <strong>{t("EMAIL_ADDRESS")}</strong>
                <p>
                  <a>{details.email}</a>
                </p>
              </div>
              <div class="wi-50 account_address">
                <strong>{t("CREATION_DATE")}</strong>
                <p>
                  <a>
                    <time class="" timestamp="2024-06-21T05:20:19.570Z">
                      {details &&
                        details?.createdAt &&
                        formatDate(details?.createdAt)}
                    </time>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Spin spinning={authenticate.isLoading || loading}>
          <div className="setting-find-number dashboard-box">
            <label className="setting-billing-heading">
              <span className="dot"></span>
              <span>{t("SETTINGS_BILLING_INFO_LABEL")}</span>
            </label>
            <Form>
              <div className="billing-form">
                <div className="input-fx">
                  <div className="f-name-input">
                    <label>{t("SETTINGS_FIRST_NAME_LABEL")}*</label>
                    <Input
                      placeholder={t("SETTINGS_FIRST_NAME_LABEL")}
                      name="first_name"
                      onChange={handleChangeDetails}
                      value={details.first_name}
                    />
                    {error.first_name && (
                      <span style={{ color: "red" }}>{error.first_name}</span>
                    )}
                  </div>
                  <div className="l-name-input">
                    <label>{t("SETTINGS_LAST_NAME_LABEL")}*</label>
                    <Input
                      placeholder={t("LAST_NAME_lABEL")}
                      name="last_name"
                      onChange={handleChangeDetails}
                      value={details.last_name}
                    />
                    {error.last_name && (
                      <span style={{ color: "red" }}>{error.last_name}</span>
                    )}
                  </div>
                </div>
                <div className="add-inp">
                  <label>{t("SETTINGS_ADDRESS_LABEL")}*</label>
                  <Input
                    placeholder="1 Main Street"
                    name="address"
                    onChange={handleChangeDetails}
                    value={details.address}
                  />
                  {error.address && (
                    <span style={{ color: "red" }}>{error.address}</span>
                  )}
                </div>
                <div className="input-fx">
                  <div className="f-name-input">
                    <label>{t("SETTINGS_COUNTRY_LABEL")}*</label>

                    <select
                      // showSearch
                      // style={{
                      //   width: 120,
                      // }}
                      name="country"
                      onChange={handleChangeCountry}
                      value={details.country}
                    // value={selectedCountry.value}
                    // options={countryOptions}
                    >
                      {countryOptions?.map((item) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </select>
                    {error.country && (
                      <span style={{ color: "red" }}>{error.country}</span>
                    )}
                  </div>
                  <div className="l-name-input">
                    <label>{t("SETTINGS_CITY_LABEL")}*</label>
                    <Input
                      placeholder="Barcelona"
                      name="city"
                      onChange={handleChangeDetails}
                      value={details.city}
                    />
                    {error.city && (
                      <span style={{ color: "red" }}>{error.city}</span>
                    )}
                  </div>
                </div>
                <div className="input-fx">
                  <div className="f-name-input">
                    <label>{t("SETTINGS_POSTAL_CODE_LABEL")}*</label>
                    <Input
                      placeholder="0123"
                      name="zipcode"
                      onChange={handleChangeDetails}
                      value={details.zipcode}
                    />
                    {error.zipcode && (
                      <span style={{ color: "red" }}>{error.zipcode}</span>
                    )}
                  </div>
                </div>
                <div className="save-billing-btn">
                  <button
                    onClick={handleUpdateDetails}
                    className="hl_cta_wrap"
                    disabled={loading}
                  >
                    {t("SETTINGS_SAVE_BUTTON")}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </Spin>

        <div className="billing-footer-btn dashboard-box">
          <button className="logout-btn-wrap" onClick={() => { handleLogout(); }}>
            {t("LOGOUT")}
          </button>
          {role !== "admin" && (
            <button onClick={handleOpenModal}>{t("MENU_UNSUBSCRIBE")}</button>
          )}
        </div>
      </div>
      <Modal
        open={openModal}
        onCancel={handleCloseModal}
        centered
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className={renderRTLClass()}
      >
        <div className="login-modal-section">
          <div className="login-modal-title">
            <p>{t("MENU_UNSUBSCRIBE")}</p>
          </div>
          <div className="unsubscribe-modal-body">
            <div className="unsubscribe-text">
              {t("SETTING_UNSUBSCRIBE_TEXT")}
            </div>
            <div className="submit-btn-modal">
              <button onClick={handleUnsubscribe} disabled={loading}>
                {t("SETTING_UNSUBSCRIBE_SUBMIT_BUTTON")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={successPopup}
        centered
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className={renderRTLClass()}
      >
        <div className="login-modal-section">
          <div className="login-modal-title">
            <p>{t("MENU_UNSUBSCRIBE")}</p>
          </div>
          <div className="unsubscribe-modal-body">
            <div className="unsubscribe-text">
              {unsubscribeResult?.message}
              {/* {t("SETTING_UNSUBSCRIBE_SUCCESSFULL_MESSAGE")} */}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className={renderRTLClass()}
      >
        <div className="modal-container">
          <div className="modal-title">
            <p>{t("LANGUAGE")}</p>
          </div>
          <div className="modal-languages">
            <div className="df-modal-language">
              {languages.map((item, index) => (
                <div
                  className={`lang ${lang === item.code && "active"}`}
                  onClick={() => handleChangeLanguage(item.code, item.flagCode)}
                  key={index}
                >
                  <div className="flag">
                    <ReactCountryFlag
                      countryCode={item.flagCode}
                      svg
                      style={{
                        width: "22px",
                        height: "22px",
                      }}
                    />
                  </div>
                  <span>{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SettingsComponent;
