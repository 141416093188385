import React, { useEffect } from "react";
import "./footer.scss";
import mainLogo from "../../assets/header/Reversly-white-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import { useAppContextInput } from "../../App";
const FooterComponent = (props) => {
  const { page, backURL, signOption, footerSignUpKey } = props;
  const { t } = useTranslation();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const { inputRef, mobileInputRef, handleInputFocus, plan } =
    useAppContextInput();
  const footerLegalTitle = t("FOOTER_LEGAL_TITLE").replace(":", "");
  const handleNavigate = () => {
    navigate(`/${lang}`);
    if (window.screen.width > 767) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    } else {
      if (mobileInputRef && mobileInputRef.current) {
        mobileInputRef.current.focus();
      }
    }
  };
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <div className="footer-inner-container">
            <div className="df-footer">
              <div className="footer-left-contan">
                <div className="df-collogo-desc">
                  <div className="footer-logo">
                    {backURL ? (
                      <Link to={`/${lang}/tracking`}>
                        <img src={mainLogo} alt="Main Logo" width={190} height={40}/>
                      </Link>
                    ) : (
                      <Link to={`/${lang}/`}>
                        <img src={mainLogo} alt="Main Logo" width={190} height={40}/>
                      </Link>
                    )}
                  </div>
                  <div className="footer-desc">
                    <p>{t("FOOTER_TEXT")}</p>
                  </div>
                </div>
              </div>
              <div className="footer-right-contan">
                <div className="df-right-footer-menu">
                  <div className="legal-info">
                    <div className="legal-title">
                      <p>{footerLegalTitle}</p>
                    </div>
                    <div className="legal-menus">
                      <ul>
                        <li>
                          <Link to={`/${lang}/pricing`}>
                            {t("FOOTER_PRICING")}
                          </Link>
                        </li>
                        <li>
                          <Link to={`/${lang}/contact`}>
                            {t("MENU_CONTACT")}
                          </Link>
                        </li>
                        <li>
                          <Link to={`/${lang}/faq`}>{t("MENU_FAQ")}</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="account-menu">
                    <div className="legal-title">
                      <p>{t("FOOTER_ACCOUNT")}</p>
                    </div>
                    <div className="legal-menus">
                      <ul>
                        <li>
                          <Link to={`/${lang}/login`}>{t("LOGIN")}</Link>
                        </li>
                        <li>
                          {footerSignUpKey ? (
                            <Link
                              to={`#`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleInputFocus("trial");
                              }}
                            >
                              {t("SIGN_UP")}
                            </Link>
                          ) : (
                            <Link
                              to={`#`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleNavigate();
                              }}
                            >
                              {t("SIGN_UP")}
                            </Link>
                          )}
                        </li>
                        <li>
                          <Link to={`/${lang}/unsubscribe`}>
                            {t("MENU_UNSUBSCRIBE")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom-contant">
              <div className="footer-bottom-flex">
                <div className="tracelo-footer">
                  <p>Reversly - {currentYear}</p>
                </div>
                <div className="policies-footer">
                  <div className="cookies-policy">
                    <Link to={`/${lang}/cookie-policy`}>
                      <p>{t("FOOTER_COOKIES_POLICY")}</p>
                    </Link>
                  </div>
                  <div className="cookies-policy">
                    <Link to={`/${lang}/privacy-policy`}>
                      <p>{t("FOOTER_PRIVACY_POLICY")}</p>
                    </Link>
                  </div>
                  <div className="tems-policy">
                    <Link to={`/${lang}/terms`}>
                      <p>{t("FOOTER_TERM_CONDITION")}</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
