import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const NotificationList = createAsyncThunk(
  "NotificationList",
  async () => {
    try {
      const response = await AxiosInstance.get(
        `/notification/list?phone_report_id=66cd9558958f287bbc0216df&is_read=false`
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const notificationListSlice = createSlice({
  name: "NotificationList",
  initialState: {
    isLoading: false,
    notificationList: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [NotificationList.pending]: (state) => {
      state.isLoading = true;
    },
    [NotificationList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.notificationList = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [NotificationList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default notificationListSlice.reducer;
