import React, { useState } from "react";
import "./MonitoringReportModal.scss";
import ModalComponent from "../../../common/Modal/modal";
import { monitorReportIcons } from "../../../../assets/images";
import { updateMonitoring } from "../../../../redux/slice/updateMonitoringSlice";
import { useDispatch } from "react-redux";
import Notify from "../../../../../components/common/Notify/notify";
import Loader from "../../../common/loader/loader";

const MonitoringReportModal = (props) => {
  const [isLoading, setLoading] = useState(false)
  const { isOpen, setMonitorModal } = props;
  const dispatch = useDispatch();

  const handleMonitoringReport = async () => {
    setMonitorModal(false);
    // setLoading(true);
    // try {
    //   const res = await dispatch(updateMonitoring());
    //   if (res.type === "updateMonitoring/fulfilled") {
    //     Notify('success', res.payload.message, '');
    //   } else {
    //     Notify('error', res.error.message || 'An error occurred', '');
    //   }
    // } catch (error) {
    //   console.error('Monitoring report error:', error.message);
    //   Notify('error', error.message || 'Something went wrong', '');
    // } finally {
    //   setLoading(false);
    //   setMonitorModal(false);
    // }
  };

  return (
    <>
      {isLoading && <Loader />}
      <ModalComponent isOpen={isOpen} onClose={() => setMonitorModal(false)}>
      <div className="monitoring__report_modal">
        <div className="monitoring__report_img">
          <img src={monitorReportIcons} alt="" />
        </div>
        <div className="monitoring__report_info">
          <h2 className="modal__popup_title">Monitor Your Report</h2>
          <p className="modal__popup_desc">
            The monitoring feature allows you to receive real-time updates and
            access the latest data as it becomes available.
          </p>
          <p className="modal__popup_desc">
            You can turn off monitoring anytime by clicking the monitor button
            in the report again.
          </p>
        </div>
        <div class="report__popup_pay_btn">
          <p>
            <button onClick={handleMonitoringReport}>Start Monitoring</button>
          </p>
          <p>
            <button
              class="report_bg_color"
              onClick={() => setMonitorModal(false)}
            >
              Remind Me Later
            </button>
          </p>
        </div>
      </div>
    </ModalComponent>
    </>
  );
};
export default MonitoringReportModal;
