import React, { useEffect } from "react";
import "./phoneReportDashboard.scss";
import { MdOutlineSearch } from "react-icons/md";
import { Cookies } from "react-cookie";
import { parsePhoneNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import ReportList from "../../components/partials/reportList/reportList";

const PhoneReportDashboardComponent = ({
  countryShortName,
  senderNumber,
  number,
  handleOnNumberChange,
  numberError,
  handleLocateNewNumber,
  numberValidate,
  locationListData,
  loading,
  setFormatedNumber,
}) => {
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const { t } = useTranslation();

  useEffect(() => {
    if (senderNumber) {
      let fullNumber = "+" + senderNumber.replace("+", "");
      if (fullNumber.length > 8) {
        const phoneNumber = parsePhoneNumber(fullNumber);
        if (phoneNumber) {
          setFormatedNumber(phoneNumber.formatInternational());
        }
      }
    }
  }, [senderNumber]);



  return (
    <>
      <Spin spinning={loading}>
        
        <div className="main_root">
          <div className="ds--dashboard-wrap">
            <div className="main_container">
              <div className="mt-50 dashboard_search__report white-bg-wrap">
                <div className="dashboard__title">
                  <h2>{t("PR_DASHBOARD_TEXT_1")}</h2>
                </div>
                <div className="location_search">
                  <form>
                    <div className="location_form_field">
                      <PhoneInput
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                          className: "form-control",
                        }}
                        country={countryShortName?.toLowerCase()}
                        value={number}
                        placeholder={t("BANNER_INPUT")}
                        onChange={handleOnNumberChange}
                        onEnterKeyPress={handleLocateNewNumber}
                        countryCodeEditable={false}
                        autoFormat={false}
                      />
                      <button
                        type="button"
                        className="click-here m-left"
                        id="btnSubmit"
                        onClick={handleLocateNewNumber}
                      >
                        <MdOutlineSearch
                          style={{
                            fontSize: 26,
                            color: "#1677ff",
                          }}
                          country={countryShortName?.toLowerCase()}
                          value={number}
                          placeholder={t("BANNER_INPUT")}
                          onChange={handleOnNumberChange}
                          onEnterKeyPress={handleLocateNewNumber}
                          countryCodeEditable={false}
                          autoFormat={false}
                        />
                        <span>Search</span>
                      </button>
                    </div>
                    {numberError && (
                      <p
                        style={{
                          marginBottom: "1",
                          marginTop: "0",
                          color: "red",
                        }}
                      >
                        {numberError}
                      </p>
                    )}
                    {numberValidate && (
                      <p
                        style={{
                          marginBottom: "1",
                          marginTop: "0",
                          color: "red",
                        }}
                      >
                        {numberValidate}
                      </p>
                    )}
                  </form>
                </div>
              </div>
              {locationListData?.data?.length > 0 && (
                <div className="wpb_report_wrapper white-bg-wrap">
                  <div className="dashboard__title">
                    <h2>{t("PR_DASHBOARD_TITLE")}</h2>
                  </div>
                  {locationListData.data.map((item, index) => (
                    <ReportList
                      key={index}
                      item={item}
                      lang={lang}
                    />
                  ))}
                </div>
              )}
            </div >
          </div >
        </div >
      </Spin >
    </>
  );
};

export default PhoneReportDashboardComponent;
